import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link, useNavigate} from "react-router-dom";
import "../css/Receipt.css";
import { BASE_URL_API } from "../environment";
import { Modal, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

function OrderView() {
  var params = useParams();
  const navigate=useNavigate();
  const [view, setView] = useState(false);
  const [resview, setResview] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState('');
  const [availableSlots, setAvailableSlots] = useState([]);
  const [slotDate, setSlotDate]=useState(null);
  const [slotTime, setSlotTime]=useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [rejectReason, setRejectReason] = useState(null);
  const [reason, setReason] = useState(false);
  const [pinwise, setPinwise] = useState([]);

  const handleAgentChange = (event) => {
    console.log("Selected Agent:", event.target.value);
    setSelectedAgent(event.target.value);
  };
  
  const [agent, setAgent] = useState([]);
  
  //fetching all approve agent
  useEffect(() => {
    axios.get(BASE_URL_API + "users/allApprovedUsers")
      .then(res => {
        setAgent(res.data)
      // console.log('hello hello')
      //   console.log(res.data[11]._id);
      })
      .catch(err => console.log(err))
  },[]);
  
  useEffect(() => {
    fetchData();
  }, [params.id]);

  //fetching all data 
  const fetchData=()=>{
    axios
    .get(BASE_URL_API + "orders/indhistorder/" + params.id)
    .then((res) => {
      setOrderData(res.data);
      setPinwise(res.data.data.pin);
      localStorage.setItem("pout", res.data.data.result[0].pout);
       console.log(res.data.data.result[0].schedule_date)
       console.log(res.data.data.result[0].time)
      //  console.log(res.data)
      // console.log(res.data.data.result[0].pout)
      // console.log(res.data.data.result[0].pout)
      
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
  }

  //convert date string to dd-mm-yyyy format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  

  //function for rejecting order
  const rejectOrder = () => {

    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You want to reject this order !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Reject',
      cancelButtonText: 'Cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        if (rejectReason === null || rejectReason.trim() === '') {
          swalWithBootstrapButtons.fire(
            'Reason Required',
            'Please provide a reason for rejecting the order.',
            'error'
          );
        } else {
    axios
      .put(BASE_URL_API + "orders/conforder/"+ params.id+"?status=Rejected by admin", {
        reason: rejectReason 
      })
      .then((res) => {
        swalWithBootstrapButtons.fire(
          'Order Rejected!',
          'You have rejected this order.',
          'success'
        )
        console.log("Order rejected. Data from API:", res.data);
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error rejecting order:", error);
      });
    }   
    } else if (
      result.dismiss === Swal.DismissReason.cancel
    ) {
      swalWithBootstrapButtons.fire(
        'Cancelled',
        'You did not reject this order ',
        'error'
      )
      setView(false);
      fetchData();
    }
  })
  };

  //function for approving orders
  const approveOrder = () => {
    axios
      .put(BASE_URL_API + "orders/conforder/"+ params.id+"?status=Order Placed")
      .then((res) => {
        console.log("Order Approved. Data from API:", res.data);
        setView(true);
      })
      .catch((error) => {
        console.error("Error rejecting order:", error);
      });
  };

  const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
  })

  //function for assigning 
  const handleAssignClick = () => {
    const requestBody = {
      agentid: selectedAgent,
    };
  
   
    
    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You want to assign an agent !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Assign',
      cancelButtonText: 'Cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        axios
      .put(BASE_URL_API + "orders/assorder/" + params.id, requestBody)
      .then((res) => {
        swalWithBootstrapButtons.fire(
          'Assigned!',
          'You have successfully assigned an agent.',
          'success'
        )
        setView(false);
        resetSelectedAgent();
        fetchData();
        console.log("Agent Assigned Successfully. Data from API:", res.data);
      })
      .catch((error) => {
        console.error("Error assigning agent:", error);
      });
        
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          'You did not assign any agent :)',
          'error'
        )
        setView(false);
        resetSelectedAgent();
        fetchData();
      }
    })

    

      
  };
  
  const calculateTotalQuantity = (items) => {
    if (!Array.isArray(items)) return 0;
  
    return items.reduce((acc, item) => {
      const weight = parseFloat(item.weight) || 0;
      return acc + weight;
    }, 0);
  };
  
  // Calculate total quantity
  const totalQuantity = calculateTotalQuantity(
    orderData?.data?.result[0]?.item
  );
  
  const calculateSubtotal = (items) => {
    if (!Array.isArray(items)) return 0;
  
    return items.reduce((acc, item) => {
      const itemPrice = parseFloat(item.price) || 0;
      const weight = parseFloat(item.weight) || 0;
      return acc + itemPrice * weight;
    }, 0);
  };
  
  // Calculate subtotal
  const subtotal = calculateSubtotal(
    orderData?.data?.result[0]?.item
  );
  

  const resetSelectedAgent = () => {
    setSelectedAgent(""); 
  };
  
  const closeAll =()=>{
    setView(false);
    resetSelectedAgent();
  }

  const closeAllres =()=>{
    setResview(false);
    setSlotDate(null);
    setSlotTime(null);
    setSelectedDate(null);
  }

  //function for rescheduling
  const handleReschedule = () => {
    const requestBody = {
      "schedule_date": slotDate,
      "time": slotTime
      };
      if (!slotDate || !slotTime) {
        swalWithBootstrapButtons.fire(
          'Error',
          'Please select a date and time.',
          'error'
        );
        return; 
      }
    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You want to reschedule the date and time  !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Update',
      cancelButtonText: 'Cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        axios
      .post(BASE_URL_API + "orders/reschedule/" + params.id, requestBody)
      .then((res) => {
        swalWithBootstrapButtons.fire(
          'Rescheduled!',
          'You have successfully rescheduled the slot date and time.',
          'success'
        )
        setResview(false);
        fetchData();
        setSlotDate(null);
        setSlotTime(null);
        
        console.log("Rescheduled Successfully. Data from API:", res.data);
      })
      .catch((error) => {
        setSlotDate(null);
        setSlotTime(null);
        console.error("Error in rescheduling", error);
      });
        
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          'You did not rescheduled the slot date and time, now.',
          'error'
        )
        setResview(false);
        setSlotDate(null);
        setSlotTime(null);
        fetchData();
      }
    })

    

      
  };

  const showSlotModal =() =>{
    axios
      .get(BASE_URL_API + `slot/sevSlotadm?date=${formatDate(new Date())}&PIN=${pinwise}`)
      .then((res) => {
        setAvailableSlots(res.data.msg);
        setResview(true);
      })
      .catch((error) => {
        console.error("Error fetching available slots:", error);
      });
  };
  
const formatDate1 = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const formatDate2 = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${day}-${month}-${year}`;
};



return (
  <div>
    <div className="receipt-content">
      <div className="container bootstrap snippets bootdey">
        <div className="row">
          <div className="col-md-12">
            <div className="invoice-wrapper">
              <div className="payment-info">
                <div className="row">
                  <div className="col-sm-6">
                    <strong>
                      <span style={{color:"#73be44"}}>Order Id</span>
                    </strong>
                    <strong>{orderData?.data?.result[0]?.order_id}</strong>

                    <strong>
                      <span style={{color:"#73be44"}}>Order Status</span>
                    </strong>
                    {orderData?.data?.result[0]?.status === "processed" ? (
                      <strong>Payment Successful</strong>
                    ) : (
                      <strong> {orderData?.data?.result[0]?.status}</strong>
                    )}
                  </div>
                  <div className="col-sm-6 text-right">
                    <strong>
                      <span style={{color:"#73be44"}}>Order Date</span>
                    </strong>
                    <strong>
                      {formatDate(orderData?.data?.result[0]?.createdAt)}
                    </strong>

                    <strong>
                      <span style={{color:"#73be44"}}>Slot Date</span>
                    </strong>
                    <strong>{orderData?.data?.result[0]?.schedule_date}</strong>

                    <strong>
                      <span style={{color:"#73be44"}}>Slot Time</span>
                    </strong>
                    <strong>{orderData?.data?.result[0]?.time}</strong>

                    {orderData?.data?.result[0]?.status === "Created" && (
                      <div className="print float-end mt-4">
                        <Link
                          className="bg-success text-light p-2 rounded"
                          onClick={showSlotModal}
                        >
                          Reschedule Date & Time&nbsp;
                          <i className="fa fa-arrow-right" />
                        </Link>
                      </div>
                    )}

                    {orderData?.data?.result[0]?.status ===
                      "Assigned to agent" && (
                      <div className="print float-end mt-4">
                        <Link
                          className="bg-success text-light p-2 rounded"
                          onClick={showSlotModal}
                        >
                          Reschedule Date & Time&nbsp;
                          <i className="fa fa-arrow-right" />
                        </Link>
                      </div>
                    )}

                    {orderData?.data?.result[0]?.status === "Order Placed" && (
                      <div className="print float-end mt-4">
                        <Link
                          className="bg-success text-light p-2 rounded"
                          onClick={showSlotModal}
                        >
                          Reschedule Date & Time&nbsp;
                          <i className="fa fa-arrow-right" />
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
                <div className="payment-details">
                  <div className="row">
                    <div className="col-sm-6 line-items">
                      <div>
                        <strong>
                          <span className="text-bold" style={{color:"#73be44"}}>
                            Client:
                          </span>
                        </strong>
                        <strong>
                          {orderData?.data?.name}
                        </strong>
                        <p style={{ fontSize: "13px" }}>
                          {orderData?.data?.location},{" "}
                          {orderData?.data?.landmark}
                          <br />
                          {orderData?.data?.city}, {orderData?.data?.state}
                          <br />
                          <b>Pin:</b> {orderData?.data?.pin} <br />
                          <b>Mobile:</b> {orderData?.data?.mobile} <br />
                          <b>Email ID:</b> {orderData?.data?.email} <br />
                        </p>
                      </div>
                      <br />
                      <div>
                        <strong>
                          <span style={{color:"#73be44"}}>
                            Payment Details:&nbsp;
                          </span>
                        </strong>

                        {/* <b>Mode Of Payment :</b>{orderData?.data?.result[0]?.mode}<br/>
                       <b>UTR No :</b> {orderData?.data?.result[0]?.pout} <br />
                          <b>Account Holder Name :</b> {orderData?.data?.result[0]?.bank_account?.name} <br />
                          <b>Account No :</b> {orderData?.data?.result[0]?.bank_account?.account_number} <br />
                          <b>IFSC :</b> {orderData?.data?.result[0]?.bank_account?.ifsc} <br />
                       */}
                        {orderData?.data?.result[0]?.mode === "IMPS" && (
                          <p>
                            <b>Mode Of Payment :</b>
                            {orderData?.data?.result[0]?.mode}
                            <br />
                            {orderData?.data?.result[0]?.status ===
                              "processed" && (
                              <>
                                <b>UTR No :</b>{" "}
                                {
                                  orderData?.data?.result[0]?.pout?.split(
                                    "_"
                                  )[1]
                                }{" "}
                                <br />
                              </>
                            )}
                            <b>Account Holder Name :</b>{" "}
                            {
                              orderData?.data?.result[0]?.payment?.bank_account
                                ?.name
                            }{" "}
                            <br />
                            <b>Account No :</b>{" "}
                            {
                              orderData?.data?.result[0]?.payment?.bank_account
                                ?.account_number
                            }{" "}
                            <br />
                            <b>IFSC :</b>{" "}
                            {
                              orderData?.data?.result[0]?.payment?.bank_account
                                ?.ifsc
                            }{" "}
                            <br />
                          </p>
                        )}

                        {orderData?.data?.result[0]?.mode === "UPI" && (
                          <p>
                            <b>Mode Of Payment :</b>{" "}
                            {orderData?.data?.result[0]?.mode}
                            <br />
                            {orderData?.data?.result[0]?.status ===
                              "processed" && (
                              <>
                                <b>UTR No :</b>{" "}
                                {
                                  orderData?.data?.result[0]?.pout?.split(
                                    "_"
                                  )[1]
                                }{" "}
                                <br />
                              </>
                            )}
                            <b>UPI ID :</b>{" "}
                            {orderData?.data?.result[0]?.payment?.vpa?.address}{" "}
                            <br />
                          </p>
                        )}

                        {orderData?.data?.result[0]?.mode === "Cash" && (
                          <p>
                            <b>Mode Of Payment :</b>CASH
                            <br />
                          </p>
                          // {orderData?.data?.result[0]?.mode}
                        )}
                        {orderData?.data?.result[0]?.mode === "Eco" && (
                          <p>
                            <b>Mode Of Payment :</b>Eco
                            <br />
                          </p>
                        )}
                        {orderData?.data?.result[0]?.mode === "Donation" && (
                          <div>
                          <p>
                            <b>Mode Of Payment :</b>{" "}
                            {orderData?.data?.result[0]?.mode}
                          </p>
                          <p>
                            <strong>
                              <span style={{color:"#73be44"}}>
                                NGO Details:&nbsp;
                              </span>
                            </strong>
                            <b>NGO Name :</b>{" "}
                            {
                              orderData?.data?.result[0]?.NGO?.name
                            }{" "}
                            <br />
                            <b>NGO Contact No :</b>{" "}
                            {
                              orderData?.data?.result[0]?.NGO?.mobile
                            }{" "}
                            <br />
                            <b>NGO Email :</b>{" "}
                            {
                              orderData?.data?.result[0]?.NGO?.email
                            }{" "}
                          </p>
                          <p>
                            <strong>
                              <span style={{color:"#73be44"}}>
                                Transaction Details:&nbsp;
                              </span>
                            </strong>
                            <b>Account Holder Name :</b>{" "}
                            {
                              orderData?.data?.result[0]?.NGO?.ngo_bank_account?.name
                                
                            }{" "}
                            <br />
                            <b>Account No :</b>{" "}
                            {
                              orderData?.data?.result[0]?.NGO?.ngo_bank_account?.account_number
                            }{" "}
                            <br />
                            <b>IFSC :</b>{" "}
                            {
                              orderData?.data?.result[0]?.NGO?.ngo_bank_account?.ifsc
                            }{" "}
                            <br />
                            {orderData?.data?.result[0]?.status ===
                              "processed" && (
                              <>
                                <b>UTR No :</b>{" "}
                                {
                                  orderData?.data?.result[0]?.pout?.split(
                                    "_"
                                  )[1]
                                }{" "}
                                <br />
                              </>
                            )}
                          </p>
                          </div>
                        )}
                      </div>
                      <br />
                      <div>
                        <strong>
                          <span style={{color:"#73be44"}}>
                            Assigned Agent:&nbsp;
                          </span>
                        </strong>
                        <strong>
                          {orderData?.data?.result[0]?.agent_id
                            ? orderData?.data?.Agent_name
                            : "Not Assigned"}
                        </strong>
                        <p>
                          {orderData?.data?.result[0]?.agent_id
                            ? orderData?.data?.Agent_mobile
                            : ""}
                          <br />
                          {orderData?.data?.result[0]?.agent_id
                            ? orderData?.data?.Agent_email
                            : ""}
                          <br />
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6 line-items">
                      <div className="headers clearfix">
                        <div className="row">
                          <div className="col-4" style={{color:"#73be44"}}>
                            <b>Item</b>
                          </div>
                          <div className="col-4 " style={{color:"#73be44"}}>
                            <b>Quantity</b>
                          </div>
                          <div className="col-4 text-right" style={{color:"#73be44"}}>
                            <b>Amount</b>
                          </div>
                        </div>
                      </div>

                      <div className="items">
                        {orderData?.data?.result[0]?.item.map((item, index) => (
                          <div key={index}>
                            <div className="row item">
                              <div className="col-4 desc">{item.item_name}</div>
                              <div className="col-4 qty">
                              {item.unit === 'Kg' ? (item.weight !== null ? item.weight.toFixed(3) : 0) : item.unit === 'Pc' ? item.weight : ''} {item.unit}

                              </div>
                              <div className="col-4 amount text-right">
                                ₹
                                {(
                                  (item.weight !== null ? item.weight : 0) *
                                  item.price
                                ).toFixed(2)}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="total text-right ">
                        {/* <div className="extra-notes ">
                          Total Quantity :&nbsp;
                          <b className="text-success">
                            {totalQuantity.toFixed(3)} Kg
                          </b>
                        </div> */}

                        <div className="field grand-total">
                          Total{" "}
                          <span>
                            <strong>₹{subtotal.toFixed(2)}</strong>{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="line-items">
                  <div className="row no-print">
                    {orderData?.data?.result[0]?.status === "Created" && (
                      <div className="col-12">
                        <div className="print float-start">
                          <Link
                            className="bg-danger text-light"
                            onClick={()=>{setReason(true)}}
                          >
                            <i className="fa fa-trash" />
                            &nbsp; Reject
                          </Link>
                        </div>
                        <div className="print float-end d-flex">
                          <div>
                            <Link
                              className="bg-success text-light"
                              onClick={approveOrder}
                            >
                              Approve & Assign&nbsp;
                              <i className="fa fa-arrow-right" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}

                    {orderData?.data?.result[0]?.status ===
                      "Rejected by admin" && (
                      <div className="col-12">
                        <div className="print text-center">
                          <b className="text-danger">
                            {" "}
                            This Order has been rejected by the admin
                          </b>
                        </div>
                        <div className=" text-center">
                        <b className="">Reason :</b>
                        <div className="mb-2 bg-light p-2" style={{ maxHeight: "5.5rem",maxWidth: "100%", overflowY: "auto" }}>
                        {orderData?.data?.result[0]?.reason === null
                            ? "Reason was not mentioned previously"
                            : orderData?.data?.result[0]?.reason}
                        
                        </div> 
                      </div>
                        {/* <div className="print float-end d-flex"></div> */}
                      </div>
                    )}

                    {orderData?.data?.result[0]?.status === "processing" && (
                      <div className="col-12">
                        <div className="print text-center">
                          <b style={{color:"#73be44"}}>
                            This Order has been Completed Successfully and
                            payment is in processing.
                          </b>
                        </div>
                        {/* <div className="print float-end d-flex"></div> */}
                      </div>
                    )}

                    {orderData?.data?.result[0]?.status === "processed" && (
                      <div className="col-12">
                        <div className="print text-center">
                          <b style={{color:"#73be44"}}>
                            This Order has been Completed Successfully
                          </b>
                        </div>
                        <div className="print float-end d-flex"><Link to={params.id+"/print"}>Show Receipt</Link></div>
                      </div>
                    )}

                    {orderData?.data?.result[0]?.status === "Order Placed" && (
                      <div className="col-12">
                        <div className="print float-start">
                          <Link
                            className="bg-danger text-light"
                            onClick={()=>{setReason(true)}}
                          >
                            <i className="fa fa-trash" />
                            &nbsp; Reject
                          </Link>
                        </div>
                        <div className="print float-end d-flex">
                          <div>
                            <Link
                              className="bg-success text-light"
                              onClick={() => setView(true)}
                            >
                              Assign Agent&nbsp;
                              <i className="fa fa-arrow-right" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}

                    {orderData?.data?.result[0]?.status ===
                      "Assigned to agent" && (
                      <div className="col-12">
                        <div className="print float-start">
                          <Link
                            className="bg-danger text-light"
                            onClick={()=>{setReason(true)}}
                          >
                            <i className="fa fa-trash" />
                            &nbsp; Reject
                          </Link>
                        </div>
                        <div className="print float-end d-flex">
                          <div>
                            <Link
                              className="bg-success text-light"
                              onClick={() => setView(true)}
                            >
                              Change Agent&nbsp;
                              <i className="fa fa-arrow-right" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* assign to agent modal */}
                <Modal
                  size="md"
                  show={view}
                  onHide={closeAll}
                  aria-labelledby="example-modal-sizes-title-md"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                      Assign To Agent
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <label>Select An Agent : &nbsp;</label>
                      <select
                        onChange={handleAgentChange}
                        value={selectedAgent}
                      >
                        <option value="" disabled>
                          Select an Agent
                        </option>
                        {agent.map(
                          (agentItem) =>
                            agentItem.isActive &&
                            agentItem._id !==
                              orderData?.data?.result[0]?.agent_id && (
                              <option key={agentItem._id} value={agentItem._id}>
                                {agentItem.name}
                              </option>
                            )
                        )}
                      </select>
                    </div>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button variant="success" onClick={handleAssignClick}>
                      Assign
                    </Button>
                  </Modal.Footer>
                </Modal>

              {/* modal reschedule slot time and date */}
                <Modal
                  size="lg"
                  show={resview}
                  onHide={closeAllres}
                  aria-labelledby="example-modal-sizes-title-md"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                      Reschedule Slot Date and Time
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label style={{color:"#73be44"}}>
                        <b>Select Slot Date :</b>
                      </label>
                      <Calendar
                        onChange={(date) => {
                          formatDate(date);
                          setSlotDate(formatDate(date));
                          setSelectedDate(date);
                          setSlotTime(null);
                        }}
                        tileDisabled={({ date }) => {
                          const formattedDate = formatDate1(date);
                          return !availableSlots.some(
                            (slot) => slot.date === formattedDate
                          );
                        }}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label style={{color:"#73be44"}}>
                        <b>Select A Slot Times :</b>
                      </label>
                      {/* <div>
                       <span className="text-danger">*</span> The previous given slot time is disabled
                      </div> */}
                      {selectedDate && (
                        <div>
                          {availableSlots
                            .filter((slot) => slot.date === formatDate1(selectedDate))
                            .map((slot) => (
                              <div key={slot._id} className="row">
                                {slot.times.map((time) => {
                                  const isDisabled =
                                    formatDate2(selectedDate) === orderData?.data?.result[0]?.schedule_date &&
                                    orderData?.data?.result[0]?.time === time;

                                  return (
                                    <div
                                      key={time}
                                      className={`col-4 border border-success rounded border-3 text-center p-2 m-3 ${
                                        isDisabled ? "disabled" : ""
                                      }`}
                                    >
                                      <input
                                        type="radio"
                                        id={time}
                                        name="slotTime"
                                        value={time}
                                        checked={slotTime === time}
                                        onChange={(e) =>
                                          setSlotTime(e.target.value)
                                        }
                                        disabled={isDisabled}
                                      />
                                      <label htmlFor={time}>{time}</label>
                                    </div>
                                  );
                                })}
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="success" onClick={handleReschedule}>
                      Update
                    </Button>
                  </Modal.Footer>
                </Modal>

                {/* reject order modal */}
                <Modal
                  size="md"
                  show={reason}
                  onHide={()=>{setReason(false)}}
                  aria-labelledby="example-modal-sizes-title-md"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                      Reason
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  <div className="mb-3">
                    <label className="form-label">Write the reason:</label>
                    <textarea
                      className="form-control"
                      id="example-modal-sizes-title-lg"
                      rows="4"
                      onChange={(e) => setRejectReason(e.target.value)}
                    />
                  </div>
                  </Modal.Body>


                  <Modal.Footer>
                    <Button variant="success" onClick={rejectOrder}>
                      Reject
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
}

export default OrderView;
