import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { BASE_URL_API } from '../environment';
import { Form, Row, Col } from 'react-bootstrap'
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator';
import Swal from 'sweetalert2';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import noLogo from '../img/logo01.svg';


export const Customers = () => {
  const [view, setView] = useState(false);
  
  // CUSTOMER LIST
  const [cust, setCust] = useState([]);  
  useEffect(() => {
    axios.get(BASE_URL_API + "customers")
      .then(res => setCust(res.data))
      .catch(err => console.log(err))
  }, []);

  const fetchData = () => {
    axios
      .get(BASE_URL_API + "customers")
      .then(res => setCust(res.data))
      .catch((err) => console.log(err));
  };
  
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });
  //INACTIVE CUSTOMER
  const handleApiInactive = (id) => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You want to inactive this customer !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Inactive",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
    axios.delete(BASE_URL_API + 'customers/delete/'+id)
      .then(res => {
        swalWithBootstrapButtons.fire(
          "Inactivate!",
          "This Customer has been Inactivated.",
          "success"
        );
        fetchData();
        console.log(res.data)
      }).catch(err => console.log(err));
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      swalWithBootstrapButtons.fire(
        "Cancelled",
        "You did not inactive this customer.",
        "error"
      );
    }
  });
      
  };

  //FETCH ADDRESS
  const [address, setAddress] = useState([]);
  const adrs = (id) => {
    axios.get(BASE_URL_API + 'address/cusId/' + id)
      .then((res) => {
        setAddress(res.data)
      })
      .catch(err => console.log(err));
  };

  //VIEW CUSTOMERS
  const [cusView, setCusView] = useState([]);
  const showUserDetails = (mob,id) => {
    handleApiView(mob);
    adrs(id);
    setView(true)
  }
  const handleApiView = (num) => {
    axios.get(BASE_URL_API + 'customers/'+num)
      .then((res) => {
        setCusView(res.data);
      }).catch(err => console.log(err));
  };

  //TABLE COLUMNS
  const columns = [
    {
      dataField: 'imageURL',
      text: 'Image',
      formatter: (dataField) => {
        if (dataField) {
          return (
            <img src={"https://apisbot.ceibagreen.com/" + dataField} style={{ height: '50px', width: '50px' }} className='rounded' />
          );
        } else {
          return (
            <img src={noLogo} style={{ height: '50px', width: '50px' }} className='rounded'/>
          );
        }
      }
    },
    
    {
      dataField: 'name',
      text: 'Name',
      sort: 'asc',
      filter: textFilter(),
    }, 
    {
      dataField: 'mobile',
      text: 'Mobile',
      sort: 'asc',
      filter: textFilter(),
    },
    {
      dataField: '_id',
      text: 'Actions',
      formatter: (col,row,rowIndex,formatExtra) => {
        return (
          <>
            <Button type="button" style={{background:"#73be44", color:"#fff"}} className="btn mx-1" onClick={() => showUserDetails(row.mobile,row._id)}><i className='fa-solid fa-eye'></i></Button>
            <Button type="button" className="btn btn-primary mx-1" onClick={() => handleApiInactive(row._id)}><i class="fa fa-toggle-on" aria-hidden="true"></i></Button>
          </>
      )}
    }
  ];

  //Pagination
  const [sizePerPage, setSizePerPage] = useState(10);

  const handleTableChange = (type, { page, sizePerPage }) => {
    setSizePerPage(sizePerPage);
  };

  
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: sizePerPage,
    pageStartIndex: 1,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    hideSizePerPage: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
    onTableChange: handleTableChange,
  };
  return (
   
      <div className="card">
        <h5 className="card-header">Customers</h5>
        

        {/* Customer Table */}
          
        <BootstrapTable
        keyField='id' 
        data={cust} columns={columns}
        pagination={paginationFactory(options)} 
        filter={filterFactory()}
        search />
          
          {/* View Modal */}
          <Modal size="md" show={view} onHide={() => setView(false)} aria-labelledby="example-modal-sizes-title-md">
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                Customer Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              {
              cusView.map((item) =>
              <>
                <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Image</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                {item.imageURL ? (
    <img src={"https://apisbot.ceibagreen.com/" + item.imageURL} style={{ height: "75px", width: "75px" }} />
  ) : (
    <img src={noLogo} style={{ height: "75px", width: "75px" }} />
 ) }                </Col>
              </Row>
            <br/>
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Name</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <h4>{item.name}</h4>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Email</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <h6>{item.email}</h6>
                </Col>
              </Row>

            <br/>
            {/* {
              address.map((itim) =>
              <>
                <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Address</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <p>{itim.location},{itim.landmark},{itim.city}, {itim.state},{itim.PIN} </p>
                </Col>
              </Row>
              </>
              )} */}
              <br/>
              <Row>
                <Col xs={6} md={4}>
                  <Form.Label>Created on</Form.Label>
                </Col>
                <Col xs={12} md={8}>
                  <p>{item.createdAt}</p>
                </Col>
              </Row>
              </>
              )}
              </Form.Group>
            </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={() => setView(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      
    
  )
}
