import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_URL_API } from "../environment";
import Swal from "sweetalert2";


function SellScrap() {
    // const [isVerified, setIsVerified] = useState(false);
  const [showAllDetails, setShowAllDetails] = useState(false);
  // const [PaymentMode, setPaymentMode] = useState('');
  const Navigate = useNavigate();

  const accessToken =
    "pk.eyJ1IjoiYWRpdGkyMDE3IiwiYSI6ImNsbWhlM2tkOTJzbmkzZW14dHB5ZzhiczIifQ.clzsn05t8dyPUXKRLmMeXQ";

  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [cusname, setCusName] = useState("");
  const [cusmail, setCusMail] = useState("");
  const [location, setLocation] = useState("");
  const [pin, setPin] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [landmark, setLandmark] = useState("");

  const [isValidMobile, setIsValidMobile] = useState(false);

  const handleMobileChange = (e) => {    
    setOtp("");
    const inputMobile = e.target.value;
    setMobileNumber(inputMobile);
    setIsValidMobile(inputMobile.length === 10);
    setShowAllDetails(false);
  };

  const [isValidOtp, setIsValidOtp] = useState(false);

  const handleOtpChange = (e) => {
    const inputOtp = e.target.value;
    setOtp(inputOtp);
    setIsValidOtp(inputOtp.length === 4);
  };

  //function for login
  const LoginCheck = () => {
    axios
      .post(BASE_URL_API + "customers/login", {
        mobile: mobileNumber,
      })
      .then((res) => {
        console.log(res.data);
        console.log(res.data.length);
        Swal.fire({
          position: "center",
          icon: "success",
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //verify otp function
  const verifyOtp = () => {
    axios
      .post(BASE_URL_API + "customers/verifyOTP", {
        mobile: mobileNumber,
        OTP: otp,
      })
      .then((res) => {
        console.log(res.data);
  if(res.data.message === "Wrong OTP enter."){
    setOtp("");
    Swal.fire({
      icon: "error",
      title: res.data.message,
    });
  }
  else{
        if (res.data.customerDetails && Object.keys(res.data.customerDetails).length > 0) {
          // Existing customer
          localStorage.setItem("cusID", res.data.customerDetails._id);
          Swal.fire({
            icon: "success",
            title: "It is an existing customer, Order Now ...",
          });
          Navigate("itempage");
        } else {
          // New customer
          Swal.fire({
            icon: "info",
            title: "It is a new customer. Add all the details below for the new customer",
          });
          setShowAllDetails(true);
          console.log("showAllDetails set to true");
        }
      }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  

  const handleInputChange = (event) => {
    setPin(event.target.value);
  };

  //pincode check
  const handlePinBlur = () => {
    const getpin = pin;
    if (getpin) {
      axios
        .get(`https://api.postalpincode.in/pincode/${getpin}`)
        .then((response) => {
          const data = response.data;
          if (data && data.length > 0 && data[0].PostOffice.length > 0) {
            const city = data[0].PostOffice[0].Block;
            const state = data[0].PostOffice[0].State;
            setCity(city);
            setState(state);
          } else {
            console.log("Invalid PIN");
            Swal.fire({
              icon: "error",
              title: "Invalid PIN",
              text: "Please fill a valid pin for adding the location.",
            });
            setCity("");
            setState("");
          }
        })
        .catch((error) => {
          console.error("Error fetching PIN details:", error);
        });
    } else {
      setCity("");
      setState("");
    }
  };

  //create customer if not exist
  const CreateCustomer = async () => {
    if (
      !cusname ||
      !cusmail ||
      !mobileNumber ||
      !location ||
      !city ||
      !state ||
      !pin
    ) {
      console.error("Please fill in all required fields");
      window.alert("Please fill in all required fields");
      return;
    }

    const geoCodingUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${pin}.json?country=IN&access_token=${accessToken}`;
    const geoCodingResponse = await axios.get(geoCodingUrl);
    const features = geoCodingResponse.data.features;

    if (features.length > 0) {
      const [longitude, latitude] = features[0].center;
      console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);

      const customerData = {
        name: cusname,
        email: cusmail,
        mobile: mobileNumber,
        location: location,
        landmark: landmark,
        city: city,
        state: state,
        PIN: pin,
        latitude: latitude,
        longitude: longitude,
      };

      const response = await axios.post(
        BASE_URL_API + "customers/createProfile",
        customerData
      );
      console.log("Customer created successfully:", response.data);
      // console.log("Customer created successfully id is:", response.data.customerDetails._id);
      Swal.fire("A new customer created successfully");
      localStorage.setItem("cusID", response.data.customerDetails._id);
      // window.alert("Customer created successfully");
      Navigate("itempage");
    } else {
      console.error(
        "Unable to get latitude and longitude from the provided location"
      );
      setLocation("");
      Swal.fire("Please Provide a Valid Location");
      // window.alert('Please Provide a Valid Location');
    }
  };

  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleEmailChange = (e) => {
    const email = e.target.value.toLowerCase();
    setCusMail(email);
    const emailRegex = /\S+@\S+\.\S+/;
    setIsValidEmail(emailRegex.test(email));
  };

  //All details field of customer
  const allDetails = () => (
    <div>
      <p className="text-center mt-4 mb-2">
        <h5>Enter All Details Below </h5>
      </p>
      <p className="mt-4 mb-2 text-danger text-end mr-5">
        <b>*</b> marked fields are mandatory.
      </p>
      <div className="card-body">
        <h5>Contact Details</h5>
        <div className="p-3">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  Customer Name
                  <span className="text-danger" style={{ fontWeight: "bold" }}>
                    {" "}
                    *
                  </span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Name"
                  onChange={(e) => {
                    setCusName(e.target.value.trim());
                  }}
                  onKeyPress={(e) => {
                    const isAlphabetWithSpace = /^[A-Za-z\s]+$/;
                    const isValidInput = isAlphabetWithSpace.test(e.key);

                    if (!isValidInput) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div className="form-group">
                <label>
                  Customer Email
                  <span className="text-danger" style={{ fontWeight: "bold" }}>
                    {" "}
                    *
                  </span>
                </label>
                <input
                  type="text"
                  className={`form-control ${isValidEmail ? "" : "is-invalid"}`}
                  placeholder="Enter Email"
                  value={cusmail}
                  onChange={handleEmailChange}
                />
                {!isValidEmail && (
                  <div className="invalid-feedback">
                    Please enter a valid email address.
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Customer Mobile Number </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Mobile Number"
                  value={mobileNumber}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <h5>Address</h5>
        <div className="p-3">
          <div className="row">
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label>
                  Pin/Zip Code
                  <span className="text-danger" style={{ fontWeight: "bold" }}>
                    {" "}
                    *
                  </span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Pin "
                  onChange={handleInputChange}
                  onKeyUp={handlePinBlur}
                  onKeyPress={(event) => {
                    const pattern = /[0-9]/;
                    const inputChar = String.fromCharCode(event.charCode);

                    if (event.target.value.length === 0 && inputChar === "0") {
                      event.preventDefault();
                    }

                    if (!pattern.test(inputChar)) {
                      event.preventDefault();
                    }
                  }}
                  maxLength={6}
                />
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label>
                  City
                  <span className="text-danger" style={{ fontWeight: "bold" }}>
                    {" "}
                    *
                  </span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter City"
                  value={city}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label>Landmark</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Landmark "
                  onChange={(e) => {
                    setLandmark(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label>State</label><span className="text-danger" style={{ fontWeight: "bold" }}>
                    {" "}
                    *
                  </span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter State"
                  value={state}
                //   disabled
                  required
                  onChange={(e) => {
                    setState(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label>
                  House No, Area, Colony
                  <span className="text-danger" style={{ fontWeight: "bold" }}>
                    {" "}
                    *
                  </span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter House No, Area, Colony "
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <h5>Account Details</h5>
        <div className="p-3">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
  <label>Select Mode Of Payment :</label>
  <select class="form-select" aria-label="Default select example" onChange={(e) => setPaymentMode(e.target.value)} >
    <option disabled selected value="">Select</option>
    <option value="Cash">Cash</option>
    <option value="UPI">UPI</option>
    <option value="IMPS">Bank Account</option>
  </select>
</div>
              
            </div>
            {PaymentType()}
                       
          </div>
        </div> */}

        <div class="d-grid col-6 mx-auto">
          <button
            class="btn btn-primary text-white"
            type="button"
            onClick={CreateCustomer}
            disabled={!isValidEmail}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );

  //const PaymentType = () => {
  //   if (PaymentMode === "Cash") {
  //     return (
  //       <div className="col-md-6 mt-4">
  //         <div className="form-group">
  //           <label>Payment Mode: <strong>Cash</strong></label>
  //         </div>
  //       </div>
  //     );
  //   } else if (PaymentMode === "UPI") {
  //     return (
  //       <div className="col-md-6">
  //         <div className="form-group">
  //           <label>UPI ID :</label>
  //           <div className="input-group">
  //             <input
  //               type="text"
  //               className="form-control"
  //               placeholder="Enter UPI ID"
  //             />
  //             <div className="input-group-append">
  //               <select
  //                 class="form-select"
  //                 aria-label="Default select example"
  //               >
  //                 <option value="@ybl">@ybl</option>
  //                 <option value="@apl">@apl</option>
  //                 <option value="@ibl">@ibl</option>
  //                 <option value="@axl">@axl</option>
  //                 <option value="@oksbi">@oksbi</option>
  //                 <option value="@okhdfc">@okhdfc</option>
  //                 <option value="@icici">@icici</option>
  //                 <option value="@paytm">@paytm</option>
  //               </select>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   } else if (PaymentMode === "IMPS") {
  //     return (
  //       <div className="col-md-6">
  //         <div className="form-group">
  //           <label>Bank Account Number :</label>
  //           <input
  //             type="text"
  //             className="form-control"
  //             placeholder="Enter A/c  Number"
  //           />
  //         </div>
  //         <div className="form-group">
  //           <label>IFSC Code :</label>
  //           <input
  //             type="text"
  //             className="form-control"
  //             placeholder="Enter IFSC"
  //           />
  //         </div>
  //         <div className="form-group">
  //           <label>Account Holder Name :</label>
  //           <input
  //             type="text"
  //             className="form-control"
  //             placeholder="Enter Account Holder Name"
  //           />
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     return null;
  //   }
  // };



  return (
    //sell scrap by admin
    <div className="container-fluid h-auto pb-4">
      <div className="pt-2 pl-2">
        <h5>Sell Scrap</h5>
      </div>

      <div className="card card-default">
        <div className="d-flex justify-content-center">
          <div className="mt-4 mb-4">
            <div className="row mt-4 d-flex justify-content-center px-3 ">
              <div className="col-sm-4 col-lg-4 col-md-4 mt-2">
                <label htmlFor="mobileno">
                  Enter Mobile No
                  <span className="text-danger" style={{ fontWeight: "bold" }}>
                    {" "}
                    *
                  </span>
                </label>
              </div>
              <div className="col-sm-5 col-lg-5 col-md-5">
                <div className="input-group">
                  <input
                    type="text"
                    className={`form-control ${
                      isValidMobile && mobileNumber !== ""
                        ? "is-valid"
                        : "is-invalid"
                    }`}
                    id="inputotp"
                    placeholder="Enter 10 digit valid mobile no"
                    onChange={handleMobileChange}
                    onKeyPress={(e) => {
                      const isNumeric = /^[0-9]+$/;
                      const isValidInput = isNumeric.test(e.key);
                      if (!isValidInput) {
                        e.preventDefault();
                      }
                    }}
                    maxLength={10}
                  />
                </div>
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3">
                <button
                  type="button"
                  className={`btn btn-primary btn-md btn-block text-white ${
                    isValidMobile && mobileNumber !== "" ? "" : "disabled"
                  }`}
                  onClick={LoginCheck}
                  disabled={!isValidMobile || mobileNumber === ""}
                >
                  Get Otp
                </button>
              </div>
            </div>

            <div className="row mt-4 d-flex justify-content-center px-3 ">
              <div className="col-sm-4 col-lg-4 col-md-4 mt-2">
                <label htmlFor="mobileno">
                  Enter Otp
                  <span className="text-danger" style={{ fontWeight: "bold" }}>
                    {" "}
                    *
                  </span>
                </label>
              </div>
              <div className="col-sm-5 col-lg-5 col-md-5">
                <div className="input-group">
                  <input
                    type="text"
                    className={`form-control ${
                      isValidOtp && otp !== "" ? "is-valid" : "is-invalid"
                    }`}
                    id="inputotp"
                    placeholder="Enter otp"
                    maxLength={4}
                    value={otp}
                    onChange={handleOtpChange}
                    onKeyPress={(e) => {
                      const isNumeric = /^[0-9]+$/;
                      const isValidInput = isNumeric.test(e.key);
                      if (!isValidInput) {
                        e.preventDefault();
                      }
                    }}
                    disabled={!isValidMobile || mobileNumber === ""}
                  />
                </div>
                {!isValidOtp && otp !== "" && (
                  <div className="invalid-feedback">
                    Please enter a valid 4-digit OTP.
                  </div>
                )}
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3">
                <button
                  type="button"
                  className={`btn btn-primary btn-md btn-block text-white ${
                    isValidOtp && otp !== "" ? "" : "disabled"
                  }`}
                  onClick={verifyOtp}
                  disabled={!isValidOtp || otp === ""}
                >
                  Verify Otp
                </button>
              </div>
            </div>
          </div>
        </div>

        {showAllDetails && allDetails()}
      </div>
    </div>
  )
}

export default SellScrap
